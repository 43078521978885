import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import "./style.css";
import Translate from "../../components/Translate";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export const Recommend = () => {
  const divRef = useRef();
  const { t, i18n } = useTranslation();
  const [dao, setDao] = useState(1)
  const [dao2, setDao2] = useState("#junlan")
  const [dao3, setDao3] = useState("#rihang")
  const nav = useNavigate()

  const [scrollY, setScrollY] = useState(0);
  const sepRef = useRef();

  function logIt() {
    let offsetTop = sepRef.current.offsetTop - window.pageYOffset;

    if (offsetTop <= -2500) {
      console.log('first-------3')
      setDao(3)
      setDao2('#rihang')
      setDao3("#feicui")
    } else if (offsetTop <= -1070 && offsetTop >= -2500) {
      console.log('first-------2')
      setDao(2)
      setDao2('#junlan')
      setDao3("#feicui")
    } else if (offsetTop > -1070) {
      console.log('first-------1')
      setDao(1)
      setDao2('#junlan')
      setDao3("#rihang")
    }
  }

  useEffect(() => {
    document.documentElement.scrollTop=0

    function watchScroll() {
      window.addEventListener("scroll", logIt);
    }
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", logIt);
    };
  }, []);
  return (

    <div className="recommend">
      <div className="div">
        <div ref={sepRef}></div>
        <div className="daohang">
          <a href={dao2}>
            <img src="/img/shangjian.png" alt="" className="shangjian" onClick={() => {
              console.log(dao)
              console.log(dao2)
              if (dao === 1) {
                // setDao(2)
              } else if (dao === 2) {
                setDao(1)
                setDao2('#junlan')
              } else if (dao === 3) {
                setDao(2)
                // nav('/home/recommend#junlan',{replace:true})
                setDao2('#rihang')
              }
            }} />
          </a>


          <a href="#junlan" onClick={() => {
            setDao(1)
            setDao2('#junlan')
          }}>
            <div className="junlan item" style={
              dao == 1 ? { background: '#96b414', color: "#fff" } : {}
            }>
              {t("黄浦君澜酒店-nav")}
            </div>
          </a>
          <a href="#rihang" onClick={() => {
            setDao(2)
            setDao2('#junlan')
          }}>
            <div className="rihang item" style={
              dao == 2 ? { background: '#96b414', color: "#fff" } : {}
            }>
              {t("日航酒店-nav")}
            </div>
          </a>
          <a href="#feicui" onClick={() => {
            setDao(3)
            setDao2('#rihang')
          }}>
            <div className="feicui item" style={
              dao == 3 ? { background: '#96b414', color: "#fff" } : {}
            }>
              {t("翡翠希尔顿酒店")}
            </div>

          </a>
          <a href={dao3}>
            <img src="/img/xiajian.png" alt="" className="xiajian" onClick={() => {
              console.log(dao)
              console.log(dao2)
              console.log(dao3)
              if (dao === 1) {
                setDao(2)
                setDao3('#rihang')
              } else if (dao === 2) {
                setDao(3)
                setDao2('#junlan')
                setDao3('#feicui')
              } else if (dao === 3) {

                setDao2('#rihang')

              }
            }} />
          </a>


        </div>
        <a name='junlan'></a>
        <div className="overlap">
          <img
            className="mask-group"
            alt="Mask group"
            src="/img/recommend-img.jpg"
          />
          <Translate />
          <div className="content">
            <div className="text-wrapper-2">{t("君澜")}</div>
            <div className="text-wrapper-3" style={t('is_en') == 1 ? { whiteSpace: 'wrap', width: '630px', wordBreak: 'breakAll', fontSize: "28px" } : {}}>{t("地址")}</div>
            <hr />

            <div className="group-5">
              <div className="text-wrapper-4">{t("距离信息")}</div>
              <div className="text-wrapper-6">{t("酒店距离会场：0.1km（会场位于酒店内）")}</div>
            </div>

            <div className="group-5">
              <div className="text-wrapper-4">{t("h_price_title")}</div>
              <div className="price">
                <div className="text-wrapper-6" style={t("is_en") == '1' ? { fontSize: '22px' } : {}}>{t("高级大床房 (单早)")}</div>
                <div className="text-wrapper-7" >￥600</div>
              </div>
              <div className="price">
                <div className="text-wrapper-9" style={t("is_en") == '1' ? { fontSize: '22px' } : {}}>{t("标准双床房 (双早)")}</div>
                <div className="text-wrapper-8" >￥660</div>
              </div>
            </div>
            <div className="group-2">
              <div className="text-wrapper-4">{t("预定电话")}</div>
              <div className="text-wrapper-6">{t("姚经理 18926231396")}</div>
              <div className="xieyi">{t("预定时请说明为“小鹏汽车”参会嘉宾，即可享受协议价格")}</div>
            </div>


          </div>

        </div>
        <a name='rihang'></a>
        <div className="overlap">
          <img
            className="mask-group"
            alt="Mask group"
            src="/img/hotel2.jpg"
          />

          <div className="content">
            <div className="text-wrapper-2-2">{t("广州·日航酒店")}</div>
            <div className="text-wrapper-3" style={t('is_en') == 1 ? { whiteSpace: 'wrap', width: '630px', wordBreak: 'breakAll', fontSize: "28px" } : {}}>{t("地址: 广州市天河区华观路1961号")}</div>
            <hr />
            <div className="group-5">
              <div className="text-wrapper-4">{t("距离信息")}</div>
              <div className="text-wrapper-6">{t("酒店距离会场：17km")}</div>
              <div className="text-wrapper-6">{t("车程时间预估：30min")}</div>

            </div>
            <div className="group-5">
              <div className="text-wrapper-4">{t("h_price_title")}</div>
              <div className="price">
                <div className="text-wrapper-6" style={t("is_en") == '1' ? { fontSize: '22px' } : {}}>{t("高级房 大/双床（含单早）")} </div>
                <div className="text-wrapper-7" >￥500</div>
              </div>
              <div className="price">
                <div className="text-wrapper-9" style={t("is_en") == '1' ? { fontSize: '22px' } : {}}>{t("豪华房 大/双床（含单早）")} </div>
                <div className="text-wrapper-8" >￥600</div>
              </div>
              <div className="price">
                <div className="text-wrapper-9" style={t("is_en") == '1' ? { fontSize: '22px' } : {}}>{t("尊贵房 大/双床（含单早）")} </div>
                <div className="text-wrapper-8" >￥700</div>
              </div>
              <div style={{ textAlign: 'right' }}>
                {t("(如需另加早餐 80元/位)")}
              </div>


            </div>
            <div className="group-2">
              <div className="text-wrapper-4">{t("扫码预定")}</div>
              <div className="text-wrapper-6">
                <img src="/img/erweima.png" alt="" style={{ width: '120px', height: '120px', marginBottom: '15px' }} />
              </div>
              <div className="xieyi">{t("请长按保存二维码，并使用微信扫一扫识别打开")}</div>
              <div className="xieyi">{t("通过二维码预定房间，即可享受协议价格")}</div>

            </div>





          </div>

        </div>
        <a name='feicui'></a>
        <div className="overlap">
          <img
            className="mask-group"
            alt="Mask group"
            src="/img/hotel3.jpg"
          />

          <div className="content">
            <div className="text-wrapper-2-2">{t("广州·翡翠希尔顿酒店")}</div>
            <div className="text-wrapper-3" style={t('is_en') == 1 ? { whiteSpace: 'wrap', width: '630px', wordBreak: 'breakAll', fontSize: "28px" } : {}}>{t("地址: 广州市黄埔区科学城中心区彩路28号")}</div>
            <hr />
            <div className="group-5">
              <div className="text-wrapper-4">{t("距离信息")}</div>
              <div className="text-wrapper-6">{t("酒店距离会场：15km")}</div>
              <div className="text-wrapper-6">{t("车程时间预估：30min")}</div>

            </div>
            <div className="group-5">
              <div className="text-wrapper-4">{t("h_price_title")}</div>
              <div className="price">
                <div className="text-wrapper-6" style={t("is_en") == '1' ? { fontSize: '22px' } : {}}>{t("阳台客房 大床 (含单早)")}</div>
                <div className="text-wrapper-7" >￥580</div>
              </div>
              <div className="price">
                <div className="text-wrapper-9" style={t("is_en") == '1' ? { fontSize: '22px' } : {}}>{t("豪华客房 大床 (含单早)")}</div>
                <div className="text-wrapper-8" >￥680</div>
              </div>
              <div className="price">
                <div className="text-wrapper-9" style={t("is_en") == '1' ? { fontSize: '22px' } : {}}>{t("阳台客房 双床 (含双早)")}</div>
                <div className="text-wrapper-8" >￥680</div>
              </div>


            </div>
            <div className="group-2" >


              <div className="text-wrapper-4">{t("预定链接")}</div>
              <div className="text-wrapper-6" style={{ "left": "6px" }}>
                <a href='https://www.hilton.com/zh-hans/book/reservation/rooms/?ctyhocn=CANNIHI&arrivaDate=2024-01-22&departureDate=2024-01-28&groupCode=GGZXP&roomiNumAdults=1&cid=0M%2CWW%2CHILTONLINK%2CEN%2CDirectLink'>
                  <div className="lianjie">
                  https://www.hilton.com/zh-hans/book/reservation/rooms/?ctyhocn=CANNIHI&arrivaDate=2024-01-22&departureDate=2024-01-28&groupCode=GGZXP&roomiNumAdults=1&cid=0M%2CWW%2CHILTONLINK%2CEN%2CDirectLink
                  </div>
                </a>
              </div>
              <div className="xieyi">{t("可点击上方链接预定房间，即可享受协议价格")}</div>
              <div className="xieyi">{t("如需更多信息请咨询 吴先生 13302253915")}</div>



            </div>





          </div>

        </div>






      </div>
    </div>
  );
};


export default Recommend;
