import React from 'react';
import './style.css'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSignPostMutation, useSignGetMutation } from '../../store/signApi';
const Sign = () => {
    const [data, setData] = useState('')
    const [btnName, setBtnName] = useState('开始签到')
    const [dataItem, setDataItem] = useState('')
    const [login, setLogin] = useState(false)
    const [detail, setDetail] = useState('')
    const [zhanghao, setZhanghao] = useState('')
    const [password, setPassword] = useState('')
    const [err, setErr] = useState('')

    const [active, setActive] = useState(true)
    const [postInfo] = useSignPostMutation()
    const [getInfo] = useSignGetMutation()

    useEffect(() => {
        if(localStorage.getItem("peng_sign")){
            setLogin(true)
        }
        getInfoHandle()
    }, [])

    const getInfoHandle = () => {
        getInfo({

        }).then(res => {
            console.log(res.data)
            setData(res.data)
            // if (res.data.rs === 1) {

            // } else if (res.data.rs === 0) {
            //     alert(res.data.msg)
            // }
        })
    }

    const focusInput = () => {
        console.log('hehe')
        setBtnName('签到中')
        setActive(false)
    }

    const blurInput = () => {
        setBtnName('点击重新开始签到')
        setActive(true)
    }

    const clickBtn = () => {
        setBtnName('签到中')
        setActive(false)
        let input = document.querySelector('#input')
        input.focus()
    }
    const setDataItemHandle = (e) => {
        setDataItem(e.target.value)

    }

    const zhanghaoHandle = (e) => {
        setZhanghao(e.target.value)
    }

    const passwordHandle = (e) => {
        setPassword(e.target.value)
    }

    const loginHandle = (e) => {
        if (zhanghao === 'admin' && password === 'admin') {
            localStorage.setItem("peng_sign",true)
            setLogin(true)
        }
    }

    const onKeyDown = (e) => {
        console.log(e)
        if (e.key === 'Enter') {
            console.log('发送')
            postInfo({
                reference: dataItem
            }).then(res => {
                console.log(res.data)
                if (res.data.rs === 1) {
                    setDetail(res.data.info)
                    setErr('')
                    getInfoHandle()
                } else if (res.data.rs === 0) {
                    setErr(res.data.msg)
                    setDetail('')
                }
            })
            setDataItem('')
        }
    }

    const SignItem = () => {
        return (
            <div className='Sign'>
                <div className='record'>

                    <div className='title'>
                        签到成功记录
                    </div>
                    {
                        data.lists ? data.lists.map((item, index) => {
                            return <div key={index} className='item'>

                                <div className='suo item1'>
                                    {item.name}
                                </div>
                                <div className='suo item2'>
                                    {item.phone}
                                </div>
                                <div className='suo item3'>
                                    {item.company_name}
                                </div>
                                <div className='suo item3'>
                                    {item.site}
                                </div>
                                <div className='suo item4'>
                                    {item.check_at}
                                </div>

                            </div>
                        }) : null
                    }
                    

                </div>
                <div className='start'>
                    <div>
                    {
                        data?
                        <div className='total'>
                            总数：{data.total}&nbsp;&nbsp;&nbsp;&nbsp;已签到：{data.signed}
                        </div>
                        :null
                    }
                    
                    </div>
                    <div className='start-input' >
                        <input type="text" className="form-control" id='input' onFocus={focusInput} onBlur={blurInput} value={dataItem} onChange={setDataItemHandle} onKeyDown={onKeyDown} />
                    </div>
                    <button className={active ? "btn-start active" : "btn-start"} id='start1' onClick={clickBtn}>{btnName}</button>
                    {
                        err ? <div className='err'>错误提示&nbsp;:&nbsp;{err}</div> : null
                    }
                    {
                        detail ? <div className='result'>
                            <div className='item22'>

                                <div className='suo'>
                                    公司&nbsp;:&nbsp;
                                </div>
                                <div>
                                    {detail.company_name}
                                </div>
                            </div>
                            <div className='item22'>

                                <div className='suo'>
                                    姓名&nbsp;:&nbsp;
                                </div>
                                <div>
                                    {detail.name}
                                </div>
                            </div>
                            <div className='item22'>

                                <div className='suo'>
                                    手机号&nbsp;:&nbsp;
                                </div>
                                <div>
                                    {detail.phone}
                                </div>
                            </div>


                            <div className='item22'>

                                <div className='suo'>
                                    座位 &nbsp;:&nbsp;
                                </div>
                                <div>
                                    {detail.site}
                                </div>
                            </div>

                            <div className='item22'>

                                <div className='suo'>
                                    试乘试驾 &nbsp;:&nbsp;
                                </div>
                                <div>
                                    {detail.sj}
                                </div>
                            </div>

                            <div className='item22'>

                                <div className='suo'>
                                    身份证信息 &nbsp;:&nbsp;
                                </div>
                                <div>
                                    {detail.id_card}
                                </div>
                            </div>
                            <div className='item22'>

                                <div className='suo'>
                                    备注&nbsp;:&nbsp;
                                </div>
                                <div >
                                    {detail.remark}
                                </div>
                            </div>
                            <div className='item22'>

                                <div className='suo'>
                                    备注2&nbsp;:&nbsp;
                                </div>
                                <div>
                                    {detail.remark2}
                                </div>
                            </div>
                            <div className='item22'>

                                <div className='suo'>
                                    反馈人&nbsp;:&nbsp;
                                </div>
                                <div>
                                    {detail.fkr}
                                </div>
                            </div>

                            <div className='item22'>

                                <div className='suo'>
                                    专业组&nbsp;:&nbsp;
                                </div>
                                <div>
                                    {detail.zyz}
                                </div>
                            </div>

                            <div className='success'>签到成功</div>
                        </div> : null
                    }

                </div>
            </div>
        );
    }

    const loginItem = () => {
        return (
            <div className='Sign-login'>
                <div className='title'>
                    <h2>签到系统登录</h2>
                </div>
                <form action="">
                    <div className='item'>
                        <div className="form-group">
                            <label >账号:</label>
                            <input type="text" className="form-control" value={zhanghao} onChange={zhanghaoHandle} />
                        </div>
                    </div>

                    <div className='item'>
                        <div className="form-group">
                            <label >密码:</label>
                            <input type="text" className="form-control" value={password} onChange={passwordHandle} />
                        </div>
                    </div>
                    <div className='btn-login'>
                        <button onClick={loginHandle}>login 登录</button>
                    </div>

                </form>
            </div>
        )
    }
    return (
        <>
            {
                login ? SignItem() : loginItem()
            }
        </>
    );
}

export default Sign;
