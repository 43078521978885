import React from 'react';
import './style.css'
import { useTranslation } from 'react-i18next';
const Moment = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className='moment_kuang'>
            <div className='moment'>
               <h2>{t("p_more")}</h2>
            </div>
        </div>
    );
}

export default Moment;
