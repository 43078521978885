import React from 'react';
import { useState } from 'react';
import './index.css'
import { useUpdateMutation, useInfoMutation, useTimesMutation } from '../../store/pageApi2';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { changeXuan } from '../../store/pageSlice';
import wx from 'weixin-js-sdk'
import { useShareMutation } from '../../store/shareApi';
import { useEffect } from 'react';

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import Translate from '../../components/Translate';



const Application = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const [success, setSuccess] = useState(false)
    const [lang, setLang] = useState('zh')
    const [tijiao, settijiao] = useState(true)
    const [userId, setUserId] = useState('')
    const [times, setTimes] = useState([])
    const [erweima, setErweima] = useState('')
    const [site, setSite] = useState([])


    const [update, { isSuccess }] = useUpdateMutation()
    const [getInfo] = useInfoMutation()
    const [getTimes] = useTimesMutation()
    const nav = useNavigate()

    const { pageSlice } = useSelector(state => state);




    useEffect(() => {
        
        dispatch(changeXuan(3))
        getInfo({
            token: localStorage.getItem('peng_token')
        }).then(res => {

            console.log(res.data)
            if (res.data.rs === 1) {
                setUserId(res.data.user_id)
                if (res.data.status >= 1) {
                    setSuccess(true)
                    setErweima(res.data.qr_code)
                    if (res.data.site) {
                        setSite(res.data.site)
                    }

                }
            } else if (res.data.rs === 0) {
                localStorage.removeItem('peng_token')
                localStorage.removeItem('peng_time')
                nav('/', { replace: true })
            } else {
                alert(res.data.msg);
            }
        });

        getTimes({

        }).then(res => {
            console.log(res.data)
            setTimes(res.data)
        });


    }, [])

    const successItem = () => {
        return (
            <div className='successItem'>
                <img src="/img/hui-top-29.jpg" alt="" className='bg' />
                <div className='success'>
                    {
                        t("is_en") == 1 ?
                            <img src="/img/success-en-22.png" alt="" style={{width:'590px'}} /> :
                            <img src="/img/success-img.png" alt="" />
                    }

                </div>

                <Translate />
                <div className='info'>
                    <img src={erweima} alt="" className='erweima' />
                    <div className='text1'>
                        {t("我的参会码")}
                    </div>
                    <div className='text2'>

                        <h2>{t("参会id")}{userId}</h2>
                    </div>
                    <div className='text3'>


                     
                        <h2>{t("晚宴坐席: ")}

                            {site.table}

                        </h2> 
                        
                    </div>
                </div>
                <div className={t("is_en") == 1 ? 'shuoming-en' : 'shuoming'} >
                    <h2>{t("使用说明")}</h2>
                    <p>
                        {t("说明1")}
                        <br />
                        {t("说明2")}
                        <br />
                        {t("说明3")}
                        <br />
                        {t("说明4")}
                    </p>
                </div>
            </div>
        )
    }

    return (
        successItem()
    );
}

export default Application;
