import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'

const signApi = createApi({
    reducerPath: 'signApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://h5.aiweber.cn/xiaopeng/admin'
    }),
    tagTypes: 'students',
    endpoints(build) {
        return {

            signPost: build.mutation({
                query(data) {
                    return {
                        url: 'sign',
                        method: 'post',
                        body: {...data}
                    }
                },
            }),
            signGet: build.mutation({
                query() {
                    return {
                        url: 'histories',
                        method: 'get',

                    }
                },
            }),

        }
    }
})

export const {
    useSignPostMutation,
    useSignGetMutation
} = signApi

export default signApi