import React from 'react';
import { useState } from 'react';
import './login.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { useSmsMutation, useLoginMutation } from '../../store/pageApi2';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInfoMutation } from '../../store/pageApi2';
import { useSearchParams } from 'react-router-dom';
import { changeChannel } from '../../store/pageSlice';
import { useDispatch } from 'react-redux';
import Kv from '../kv';
const Login = (props) => {
    const nav = useNavigate()
    const [sms] = useSmsMutation()
    const [login] = useLoginMutation()
    const [num, setNum] = useState(60)
    const [phone, setPhone] = useState('')
    const [start, setStart] = useState(false)
    const [error, setError] = useState("")
    const [know, setKnow] = useState(true)
    const [donghua, setDonghua] = useState(true)
    const { t, i18n } = useTranslation();
    const [getInfo] = useInfoMutation()
    const [count] = useSearchParams()

    const dispatch = useDispatch();

    useEffect(() => {
        let date=new Date()
        let time=date.getTime()
        console.log(time)
        console.log(localStorage.getItem('peng_time'))
        console.log(localStorage.getItem('peng_token'))
        if (localStorage.getItem('peng_lan')) {

            i18n.changeLanguage(localStorage.getItem('peng_lan'));
            if (localStorage.getItem('peng_lan') == 'en') {
                document.title = ' Li Auto Global Partners Conference 2024'
            } else {
                document.title = '小鹏汽车2024年全球合作伙伴大会'
            }
        } else {

        }

        if(localStorage.getItem('peng_time')&&localStorage.getItem('peng_token')){
            let date=new Date()
            let time=date.getTime()
            if(localStorage.getItem('peng_time')>time){
                getInfo({
                    token: localStorage.getItem('peng_token')
                }).then(res => {
                    console.log(res.data)
                    if (res.data.rs === 1) {
                 
                        nav('/home',{replace:true})
        
                    } else {
        
                    }
                });
            }else{
                localStorage.removeItem('peng_token')
                localStorage.removeItem('peng_time')
            }
        }else{
            localStorage.removeItem('peng_token')
            localStorage.removeItem('peng_time')
        }

        
        const videos = document.querySelectorAll("video");
        videos.forEach((video) => {
          video.addEventListener("click", (event) => {
            event.preventDefault();
          });
        });

        const video1 = document.getElementById("myvideo1");
        const start = document.getElementById("start");
        const stop1 = document.getElementById("stop");
        start.addEventListener("click", (event) => {
          video1.play();
          stop1.style.display = "block";
          start.style.display = "none";
        });

        video1.addEventListener( "ended",function () {
            setDonghua(false)
        });

        stop1.addEventListener("click", (event) => {
            setDonghua(false)
        });

    }, [])

    const countDown = () => {
        setStart(true)
        setError("")
        setTimeout(() => {
            setNum(state => {
                if (state === 1) {
                    setStart(false)
                    setNum(60)
                } else {
                    countDown()
                    return state - 1
                }
            })
        }, 1000)
    }
    const countDown2 = () => {

        if (phone === '') {
            alert("请输入手机号码")
        } else {
            sms({
                phone
            }).then(res => {
                console.log(res.data)
                if (res.data.rs == 0) {
                    setNum(60);
                    setStart(false)
                    setKnow(false)

                } else if (res.data.rs == -1) {
                    alert(res.data.msg)
                }
                else {
                    countDown()
                }
                console.log(res)
            });
        }

    }
    const phoneTest = function (value) {
        var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
        if (
            !reg_tel.test(value)
        ) {
            return false
        } else {
            return true
        }
    }
    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required(t("v_fill_name")),
        code: Yup.number()
            .required(t("v_fill_code")),
        phone: Yup.string()
            .test('phone', t("v_fill_phone"), phoneTest)
            .required(t("v_fill_phone_require")),
    });

    const loginItem = () => {
        return <div style={{
            fontWeight: '400', maxWidth: '750px', margin: '0 auto', backgroundImage: "linear-gradient(rgb(158 184 209), rgb(158 184 209), rgb(158 184 209)", minHeight: '1380px',
        }} className='login_bg'>
            {
                know ? null : <div className='login-queren'>
                    <div >
                        请与您的采购经理联系，确认参会人员信息<br></br>
                        感谢您关注小鹏汽车 2024全球合作伙伴大会<br></br>
                        <div style={{ fontSize: '16px', lineHeight: '20px' }}>
                            Please contact the Purchasing Manager to confirm your atten-<br></br>dance.Thank you.
                        </div>

                    </div>
                    <hr />
                    <div className='login-know' onClick={() => {
                        setKnow(true)
                    }}>
                        我知道了
                        <div style={{ fontSize: '16px', lineHeight: '20px', color: '#96b414' }}>
                            I understand
                        </div>
                    </div>
                </div>
            }



            <img src="/img/login-bg-29.jpg" alt="" style={{ width: '100%' }} />
            <div style={{
                padding: '0 100px',
                backgroundSize: '100% 100%', color: '#fff', position: 'absolute', width: '750px', top: '660px'
            }}>
                <div style={know ? null : { opacity: '0.5' }}>
                    <Formik
                        initialValues={{
                            name: '',
                            phone: '',
                            code: ''
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={values => {
                            setError('')

                            login({
                                phone: values.phone,
                                code: values.code,
                                name:values.name
                            }).then(res => {
                                console.log(res)

                                if (res.data.rs === 1) {
                                    localStorage.setItem("peng_token", res.data.token)
                                    let date=new Date()
                                    let time=date.getTime()
                                    localStorage.setItem("peng_time", time+86400000)
                                    localStorage.setItem("peng_token", res.data.token)

                                    nav('/home', { replace: true })
                                } else if (res.data.rs === 0) {
                                    setKnow(false)
                                } else {
                                    setError(res.data.msg)
                                }

                            });


                        }}
                    >
                        {({ values, handleChange, handleBlur, errors, touched }) => (
                            <Form>

                                <div className="form-group form-group-login">
                                    <div className='titlelogin'>参会人姓名 Name :</div>
                                    <input type="text" name="name"
                                        value={values.name}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setPhone(e.target.value)
                                        }}
                                        onBlur={handleBlur}
                                        className={errors.name && touched.name ?
                                            "input-error form-control form-control-login" : "form-control form-control-login"}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className='err'>{errors.name}</div>
                                    ) : null}
                                </div>

                                <div className="form-group form-group-login">
                                    <div className='titlelogin'>参会人手机号 Phone Number :</div>
                                    <input type="number" name="phone"
                                        value={values.phone}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setPhone(e.target.value)
                                        }}
                                        onBlur={handleBlur}
                                        className={errors.phone && touched.phone ?
                                            "input-error form-control form-control-login" : "form-control form-control-login"}
                                    />
                                    {errors.phone && touched.phone ? (
                                        <div className='err'>{errors.phone}</div>
                                    ) : null}
                                </div>
                                <div className="form-group form-group-login" >
                                    <div className='titlelogin'>验证码 Code :</div>


                                    <div style={{ display: 'flex' }}>
                                        <input type="number" name="code"
                                            value={values.code}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={errors.code && touched.code ?
                                                "input-error form-control form-control-login input-group-yan" : "form-control form-control-login input-group-yan"}

                                        />
                                        {
                                            start ? <button className="btn btn-default input-groupbtn" type="button" >倒计时 {num}</button> : <button className="btn btn-default input-groupbtn" type="button" onClick={countDown2}>
                                                <div style={{ marginTop: '4px' }}>
                                                    获取验证码
                                                </div>
                                                <div>
                                                    Get Code
                                                </div>
                                            </button>
                                        }
                                    </div>

                                    {errors.code && touched.code ? (
                                        <div className='err'>{errors.code}</div>
                                    ) : null}

                                    {error && error ? (
                                        <div className='err'>{error}</div>
                                    ) : null}
                                </div>
                                <button type='submit' className="btn btn-default form-control form-control-login btn-submit "
                                    style={{ margin: '20px 0 0px 0', color: '#fff', textTransform: "uppercase" }}
                                >登录</button>
                            </Form>
                        )}
                    </Formik>
                    <div style={{ textAlign: 'center' }}>
                        <img src="/img/logo.png" alt="" style={{ width: '160px', marginTop: '100px', marginBottom: '40px' }} />
                    </div>
                </div>
            </div>
        </div>
    }

    const donghuaItem = () => {
        return (
            <>
                <div className='video-bg'>
                    {/* <img src="/img/enter_1.png" alt="" id="start" /> */}

                    <img src="/img/start-1-3.png" alt="" id="start" />

                    <div id="stop">跳过动画</div>

                    <video
                        id="myvideo1"
                        name="myvideo1"
                        style={{ "objectFit": "fill", "position": "fixed" }}
                        playsInline={true}
                        webkit-playsinline={"true"}
                        x-webkit-airplay="allow"
                        airplay="allow"
                        x5-video-player-type="h5"
                        x5-video-player-fullscreen={"true"}
                        x5-video-orientation="portrait"
                        src="/img/video-1-3.mp4"
                        width="100%"
                        poster="/img/WechatIMG216.jpg"
                    ></video>

                </div>
            </>
        )

    }
    return (
        <>
            {
                donghua ? donghuaItem() : loginItem()
            }
        </>
    );


}

export default Login;
