import { createSlice } from "@reduxjs/toolkit";

const pageSlice=createSlice({
    name:'pageSlice',
    initialState:{
        name:'',
        channel:''
    },
    reducers:{
        changeName(state,action){
            state.name=action.payload
        },
        changeXuan(state,action){
            state.xuan=action.payload
            console.log(state.xuan)
        },
        changeChannel(state,action){
            state.channel=action.payload
        },
    }
})

export const {
    changeName,changeXuan,changeChannel
}=pageSlice.actions

export default pageSlice