import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";;


const Translate = () => {
    
    const { t, i18n } = useTranslation();
    useEffect(() => {

        if (localStorage.getItem('peng_lan')) {
            i18n.changeLanguage(localStorage.getItem('peng_lan'));
            if (localStorage.getItem('peng_lan') == 'en') {
                setLang('en')
            } else {
                setLang('zh')
            }
        } else {

        }
    }, [])
    const [lang, setLang] = useState('zh')
    return (
        <div>
            <div className='translate'>
                <label className="radio-inline-lang radio-inline" >
                    <input
                        type="radio"
                        name="lang"
                        value='zh'
                        onChange={(e) => {
                            i18n.changeLanguage('zh');
                            setLang('zh')
                            localStorage.setItem('peng_lan', 'zh')
                        }}
                        checked={lang === 'zh'}
                    /> 
                    <div style={{marginLeft:'10px'}}>
                    简体中文
                    </div>
                </label>
                <label className="radio-inline" >
                    <input
                        type="radio"
                        name="lang"
                        value='en'
                        onChange={(e) => {
                            i18n.changeLanguage('en');
                            setLang('en')
                            localStorage.setItem('peng_lan', 'en')
                        }}
                        checked={lang !== 'zh'}
                    /> 
                    <div style={{marginLeft:'10px'}}>
                    English
                    </div>
                </label>
            </div>
        </div>
    );
}

export default Translate;
